var render = function () {
  var _vm$currentDevice, _vm$computedBilletInf, _vm$computedBilletInf2, _vm$computedBilletInf3, _vm$computedBilletInf4, _vm$currentDevice2, _vm$currentDevice4, _vm$currentDevice5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "mouseleave": function ($event) {
        return _vm.onTrigger('OnLeave');
      },
      "touchmove": function ($event) {
        return _vm.onTrigger('OnScroll');
      },
      "wheel": function ($event) {
        return _vm.onTrigger('OnScroll');
      }
    }
  }, [_vm.popups.length ? _c('popup', {
    ref: "$popup"
  }) : _vm._e(), _vm._v(" "), _vm.$route.fullPath.includes('starter') && ((_vm$currentDevice = _vm.currentDevice) === null || _vm$currentDevice === void 0 ? void 0 : _vm$currentDevice.alias) === 'desktop' && _vm.computedBilletInfo && _vm.isShowBillet ? _c('a', {
    staticClass: "customer__billet",
    attrs: {
      "href": (_vm$computedBilletInf = _vm.computedBilletInfo) === null || _vm$computedBilletInf === void 0 ? void 0 : _vm$computedBilletInf.link
    }
  }, [_c('div', {
    staticClass: "customer__billet-text"
  }, [_vm._v("\n            " + _vm._s((_vm$computedBilletInf2 = _vm.computedBilletInfo) === null || _vm$computedBilletInf2 === void 0 ? void 0 : _vm$computedBilletInf2.text) + " \n            "), _c('u', [_vm._v("\n                " + _vm._s((_vm$computedBilletInf3 = _vm.computedBilletInfo) === null || _vm$computedBilletInf3 === void 0 ? void 0 : _vm$computedBilletInf3.accent) + "\n            ")])]), _vm._v(" "), _c('cross-icon', {
    staticClass: "customer__billet-cross-icon",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.closeBillet.apply(null, arguments);
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('menu-layout', {
    class: ['customer', {
      'customer__has-billet': ((_vm$computedBilletInf4 = _vm.computedBilletInfo) === null || _vm$computedBilletInf4 === void 0 ? void 0 : _vm$computedBilletInf4.text) && ((_vm$currentDevice2 = _vm.currentDevice) === null || _vm$currentDevice2 === void 0 ? void 0 : _vm$currentDevice2.alias) === 'mobile'
    }],
    attrs: {
      "navigation": _vm.navigation,
      "unread-notifications-count": _vm.unreadNotificationsCount
    },
    on: {
      "on-notifications": function ($event) {
        return _vm.$router.push('/notifications');
      }
    },
    scopedSlots: _vm._u([{
      key: "billet",
      fn: function () {
        var _vm$currentDevice3, _vm$computedBilletInf5, _vm$computedBilletInf6, _vm$computedBilletInf7;

        return [_vm.$route.fullPath.includes('starter') && ((_vm$currentDevice3 = _vm.currentDevice) === null || _vm$currentDevice3 === void 0 ? void 0 : _vm$currentDevice3.alias) === 'mobile' && _vm.computedBilletInfo && _vm.isShowBillet ? _c('a', {
          staticClass: "customer__billet",
          attrs: {
            "href": (_vm$computedBilletInf5 = _vm.computedBilletInfo) === null || _vm$computedBilletInf5 === void 0 ? void 0 : _vm$computedBilletInf5.link
          }
        }, [_c('span', {
          staticClass: "customer__billet-text"
        }, [_vm._v("\n                    " + _vm._s((_vm$computedBilletInf6 = _vm.computedBilletInfo) === null || _vm$computedBilletInf6 === void 0 ? void 0 : _vm$computedBilletInf6.text) + " \n                    "), _c('u', [_vm._v("\n                        " + _vm._s((_vm$computedBilletInf7 = _vm.computedBilletInfo) === null || _vm$computedBilletInf7 === void 0 ? void 0 : _vm$computedBilletInf7.accent) + "\n                    ")])]), _vm._v(" "), _c('cross-icon', {
          staticClass: "customer__billet-cross-icon",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.closeBillet.apply(null, arguments);
            }
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, ((_vm$currentDevice4 = _vm.currentDevice) === null || _vm$currentDevice4 === void 0 ? void 0 : _vm$currentDevice4.alias) === 'desktop' ? {
      key: "catalog",
      fn: function () {
        return [_c('the-catalog', {
          staticClass: "customer__catalog",
          attrs: {
            "catalog-items": _vm.catalog
          }
        })];
      },
      proxy: true
    } : null, ((_vm$currentDevice5 = _vm.currentDevice) === null || _vm$currentDevice5 === void 0 ? void 0 : _vm$currentDevice5.alias) === 'mobile' ? {
      key: "aside-catalog",
      fn: function () {
        return [_c('the-catalog', {
          staticClass: "customer__aside-catalog",
          attrs: {
            "catalog-items": _vm.catalog,
            "hide-profile-info": "",
            "aside": ""
          }
        })];
      },
      proxy: true
    } : null, {
      key: "aside-profile",
      fn: function () {
        return [_c('the-mobile-profile', {
          staticClass: "customer__aside-profile",
          attrs: {
            "catalog-items": _vm.catalog,
            "navigation": _vm.mobileNavigation
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('nuxt')];
      },
      proxy: true
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }